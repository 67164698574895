export const getElementByText = ({
  elementType,
  text,
  callback,
  fallbackSelector,
  disconnectFn,
}: {
  elementType: string
  text: string
  callback: () => void
  fallbackSelector?: string
  disconnectFn: () => void
}) => {
  const element = document.evaluate(
    `//${elementType}[contains(text(), "${text}")]`,
    document,
    null,
    XPathResult.FIRST_ORDERED_NODE_TYPE,
    null,
  ).singleNodeValue

  const fallbackElement = document.querySelector(fallbackSelector)

  if (element) {
    disconnectFn()
    callback()
  } else if (fallbackElement) {
    disconnectFn()
  } else {
    return
  }
}

export const waitForElementByText = (
  elementType: string,
  text: string,
  callback: () => void,
  fallbackSelector?: string,
) => {
  const observer = new MutationObserver(() => {
    getElementByText({
      elementType,
      text,
      callback,
      fallbackSelector,
      disconnectFn: () => observer.disconnect(),
    })
  })
  observer.observe(document.body, { childList: true, subtree: true })
}
