import React, { createContext, useMemo, useState } from 'react'

const ErrorContext = createContext({
  hasError: false,
  updateError: (_value: boolean) => {},
})

const ErrorContextProvider = ({ children }) => {
  const [hasError, setHasError] = useState(false)

  const updateError = (value: boolean) => {
    setHasError(value)
  }

  return (
    <ErrorContext.Provider value={useMemo(() => ({ hasError, updateError }), [hasError])}>
      {children}
    </ErrorContext.Provider>
  )
}

export { ErrorContext, ErrorContextProvider }
