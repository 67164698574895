import { waitForElementByText } from './waitForElementByText'

export const checkNoReferer = async (reloadFn: () => void) => {
  waitForElementByText(
    'div',
    'No referer',
    () => {
      reloadFn()
    },
    '.vp-row,vp-ui,vp-pt-1',
  )
}
