import type { ContextType, ErrorInfo, ReactNode } from 'react'
import { Component } from 'react'
import dynamic from 'next/dynamic'

import { ErrorContext } from '@cms/context/errorContext'

const ErrorVisual = dynamic(() =>
  import('@cms/components/ErrorVisualWrapper').then((mod) => mod.default),
)

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  declare context: ContextType<typeof ErrorContext>
  static contextType = ErrorContext

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.context.updateError(true)
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.context.hasError) {
      return <ErrorVisual isClientSideException />
    }

    return this.props.children
  }
}

export default ErrorBoundary
